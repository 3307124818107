import axios from './axios.service'

const method = 'signatures'

class SignatureService {
  async index(queryData) {
    try {
      const response = await axios.get(`/${method}`, {
        params: { ...queryData }
      })
      return response.data
    } catch (error) {
      return error
    }
  }

  async show(id) {
    try {
      const response = await axios.get(`/${method}/${id}`)
      return response.data
    } catch (error) {
      return error
    }
  }

  async delete(rowClicked) {
    try {
      const response = await axios.delete(`/${method}/${rowClicked}`)
      return response
    } catch (error) {
      return error.response
    }
  }
}

export default new SignatureService()
