<template>
  <div id="items">
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="mb-0">Assinatura de e-mail</h4>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol col="12">
                <Search @search="updateSearch" />
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12">
                <DataTable
                  :sorter="query.sorter"
                  :items="datatable.items"
                  :fields="datatable.fields"
                  :enable-export="false"
                  base-route="/signature/view/"
                  @sorterValue="(event) => (query.sorter = event)"
                  @row-clicked="handleRowClick"
                  @update:checked="updateStatus"
                >
                  <template #actions="{ id }">
                    <DatatableEdit :url="`/signature/view/${id}`" />
                    <DatatableRemove
                      @row-clicked="handleRowClick"
                      @deleted="deleteItem"
                    />
                  </template>
                </DataTable>
              </CCol>
            </CRow>
            <CRow class="mt-2">
              <CCol lg="12">
                <Pagination
                  :page="query.page"
                  :per-page="query.perpage"
                  :pages="pagination.pages"
                  :total="pagination.total"
                  :items="pagination.items"
                  @update:active-page="(page) => (query.page = page)"
                  @update:perpage="(perpage) => (query.perpage = perpage)"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DataTable from '@/components/ui/Datatable'
import DatatableEdit from '@/components/ui/DatatableActions/Edit'
import DatatableRemove from '@/components/ui/DatatableActions/Remove'
import Pagination from '@/components/ui/Pagination'
import Search from '@/components/ui/Search'
import Service from '@/services/signature.service'

export default {
  metaInfo: {
    title: 'Assinaturas',
    titleTemplate: 'Manager - Rodonaves Iveco - %s'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_unities')) {
        vm.$router.push('/404')
      }
    })
  },

  components: {
    DataTable,
    DatatableEdit,
    DatatableRemove,
    Pagination,
    Search
  },

  data() {
    return {
      datatable: {
        items: [],
        fields: [
          {
            key: 'name',
            label: 'Name',
            sorter: false
          },
          {
            key: 'email',
            label: 'E-mail',
            sorter: false
          },
          {
            key: 'responsibility',
            label: 'Cargo',
            sorter: false
          },
          {
            key: 'city[0]name',
            label: 'Cidade(unidade)',
            sorter: false
          },
          {
            key: 'change_of_data',
            label: 'Atualizado em',
            sorter: false,
            formatDateTime: true
          },
          {
            key: 'actions',
            label: 'Ações',
            sorter: false,
            _style: 'width: 12%;'
          }
        ]
      },
      query: {
        search: '',
        page: 1,
        perpage: localStorage.getItem('perPageItem')
          ? parseFloat(localStorage.getItem('perPageItem'))
          : 10,
        pagination: true,
        status: null,
        sorter: {
          asc: false,
          column: 'updated_at'
        }
      },
      pagination: {
        total: 0,
        pages: 0,
        items: 0
      },
      statusOptions: [
        {
          value: null,
          label: 'Todos os status'
        },
        {
          label: 'Ativo',
          value: true
        },
        {
          label: 'Inativo',
          value: false
        }
      ],
      rowCliked: {}
    }
  },

  watch: {
    query: {
      handler: 'updateDataTable',
      deep: true
    }
  },

  async created() {
    await this.updateDataTable()
  },

  methods: {
    async updateDataTable() {
      const response = await Service.index(this.query)
      const { data, pagination } = response

      if (data) {
        this.pagination = {
          ...pagination,
          pages: pagination.lastPage,
          items: data.length
        }

        this.datatable.items = response.data.map((item) => ({ ...item }))
      }
    },

    async updateSearch(searchText) {
      this.query.search = searchText
      this.query.page = 1

      await this.updateDataTable()
    },

    handleRowClick(event) {
      this.rowCliked = event
    },

    async deleteItem() {
      const response = await Service.delete(this.rowCliked.id)

      if (response.status === 200) {
        this.$notify({
          group: 'notifications',
          type: 'success',
          text: 'Item excluído com sucesso!'
        })
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          text: 'Não foi possivel excluir o item, por favor tente novamente'
        })
      }

      await this.updateDataTable()
    }
  }
}
</script>

<style lang="scss">
#items {
  .orderButton {
    margin-right: 10px;
  }

  .thumb {
    width: 80px !important;
  }
}
</style>
